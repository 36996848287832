import StraightenIcon from "@mui/icons-material/Straighten";
import { Control } from "@components";
import { RefObject, useEffect, useRef } from "react";
import RulerControl from "@mapbox-controls/ruler";
import { MapRef } from "react-map-gl";
import { theme } from "@config";
import { Tooltip } from "@mui/material";

interface IMeasureControlProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
}

/**
 * Measure control for the map. implements the RulerControl
 */
export const MeasureControl = ({ mapRef }: IMeasureControlProps) => {
    const measureRef = useRef<RulerControl | null>(null);
    const isDragging = useRef(false);

    useEffect(() => {
        // Wait for the map to be initialized
        if (!mapRef.current) return;

        const mapInstance = mapRef.current;

        const measure = new RulerControl({
            units: "miles",
            labelFormat: (n) => `${n.toFixed(2)} mi`,
            invisible: true,
            linePaint: {
                "line-color": theme.palette.primary.main,
            },
        });

        measureRef.current = measure;
        mapInstance.addControl(measure);

        const handleTouchStart = () => {
            isDragging.current = false;
        };

        const handleTouchMove = () => {
            isDragging.current = true;
        };

        const handleTouchEnd = (event: TouchEvent) => {
            if (!isDragging.current) {
                const touch = event.changedTouches[0];
                const point = mapInstance.unproject([
                    touch.clientX,
                    touch.clientY,
                ]);
                if (point) {
                    const clickEvent = new MouseEvent("click", {
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                        bubbles: true,
                        cancelable: true,
                    });
                    mapInstance.getCanvas().dispatchEvent(clickEvent);
                }
            }
        };

        const mapCanvas = mapInstance.getCanvas();
        mapCanvas.addEventListener("touchstart", handleTouchStart);
        mapCanvas.addEventListener("touchmove", handleTouchMove);
        mapCanvas.addEventListener("touchend", handleTouchEnd);

        return () => {
            mapInstance.removeControl(measure);
            mapCanvas.removeEventListener("touchstart", handleTouchStart);
            mapCanvas.removeEventListener("touchmove", handleTouchMove);
            mapCanvas.removeEventListener("touchend", handleTouchEnd);
        };
    }, [mapRef]);

    // handles activation and deactivation of the measure control
    const handleMeasure = () => {
        if (measureRef.current) {
            if (measureRef.current.isActive) {
                measureRef.current.deactivate();
            } else {
                measureRef.current.activate();
            }
        }
    };

    return (
        <Tooltip
            title={"Measure tool"}
            PopperProps={{
                disablePortal: true,
            }}
            placement="left"
        >
            <Control onClick={handleMeasure}>
                <StraightenIcon />
            </Control>
        </Tooltip>
    );
};
