import { getOrganizations } from "@api";
import { Stack } from "@mui/material";
import {
    FormTextFieldElement,
    FormEmailElement,
    FormAutoCompleteElement,
} from "@rhf-kit/mui";
import { useQuery } from "@tanstack/react-query";

export const EditUserForm = () => {
    const { data: organizationOptions, isPending: organizationOptionsLoading } =
        useQuery({
            queryKey: ["organization-options"],
            queryFn: () => getOrganizations(),
        });

    return (
        <Stack spacing={2}>
            <FormTextFieldElement
                name={"first_name"}
                label={"First Name"}
                fullWidth
                autoFocus
            />
            <FormTextFieldElement
                name={"last_name"}
                label={"Last Name"}
                fullWidth
                autoFocus
            />
            <FormEmailElement
                name={"email"}
                label={"Email"}
                fullWidth
                autoFocus
            />
            <FormAutoCompleteElement
                name={"organizations"}
                label={"Organizations"}
                loading={organizationOptionsLoading}
                options={organizationOptions?.items ?? []}
                getOptionLabel={(option) => option?.name ?? ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                multiple
            />
        </Stack>
    );
};
