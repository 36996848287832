import { getUserById } from "@api";
import { BackButton, DashboardContainer } from "@components";
import { Box } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { EditUserCard } from "./components/EditUserCard";

export default function EditUsers() {
    const { userId } = useParams();

    const { data } = useSuspenseQuery({
        queryKey: ["userToEdit", userId],
        queryFn: () => getUserById(userId || -1),
    });

    return (
        <DashboardContainer title={"User Management"}>
            <Box>
                <BackButton />
            </Box>
            <Box maxWidth={"md"}>
                <EditUserCard user={data} />
            </Box>
        </DashboardContainer>
    );
}
