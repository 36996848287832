import { IOrganization } from "@api";
import { DashboardContainer } from "@components";
import { useState } from "react";
import { DeleteOrganizationModal, OrganizationsTable } from "./components";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { appRoutes, generateRoute } from "@config";
import { Button } from "@mui/material";

const Organizations = () => {
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [organization, setOrganization] = useState<
        IOrganization | undefined
    >();

    const handleAddUserClick = () => {
        navigate(generateRoute(appRoutes.admin.organizations.create, {}));
    };

    const handleOpenDeleteModal = (organization: IOrganization) => {
        setOrganization(organization);
        setDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
        setOrganization(undefined);
    };

    return (
        <DashboardContainer
            title={`Organization Management`}
            action={
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    size="small"
                    onClick={handleAddUserClick}
                >
                    add organization
                </Button>
            }
        >
            <OrganizationsTable handleOpenDelete={handleOpenDeleteModal} />

            <DeleteOrganizationModal
                open={deleteModal}
                organization={organization}
                handleClose={handleCloseDeleteModal}
            />
        </DashboardContainer>
    );
};

export default Organizations;
