import { IAnnotation, IGeometryCreate, ILocation } from "@api";
import { Box, Stack } from "@mui/material";
import { RefObject } from "react";
import { MapRef } from "react-map-gl";
import { IViewState } from "../../location-editor/LocationSelect.tsx";
import { FullscreenControl } from "./FullscreenControl.tsx";
import { GeolocateControl } from "./GeolocateControl.tsx";
import { LocationControl } from "./LocationControl.tsx";
import { MeasureControl } from "./MeasureControl.tsx";

interface IControlContainerProps {
    /**
     * Reference to the map
     */
    mapRef: RefObject<MapRef>;
    /**
     * Current location
     */
    location: ILocation;
    /**
     * Current view state of the map
     */
    viewState: IViewState;
    /**
     * New geometry to create
     */
    newGeometry?: IGeometryCreate;
    /**
     * Set the new geometry
     */
    setNewGeometry: (geometry: IGeometryCreate | undefined) => void;

    /**
     * Annotation currently being edited
     */
    annotationEditing?: IAnnotation;
}

export const ControlContainer = ({
    mapRef,
    location,
    viewState,
    newGeometry,
}: IControlContainerProps) => {
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            height={"100%"}
        >
            <Stack
                justifyContent={"space-between"}
                alignSelf={"flex-end"}
                height={"100%"}
                mb={4}
            >
                <Stack p={2} spacing={1}>
                    <FullscreenControl
                        mapRef={mapRef}
                        newGeometry={newGeometry}
                    />
                    <LocationControl
                        mapRef={mapRef}
                        location={location}
                        viewState={viewState}
                    />
                    <GeolocateControl
                        mapRef={mapRef}
                        showLocation={false}
                        viewState={viewState}
                    />
                </Stack>

                <Stack p={2} spacing={1}>
                    <MeasureControl mapRef={mapRef} />
                </Stack>
            </Stack>
        </Box>
    );
};
