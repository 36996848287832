import { API_URL } from "@config";
import axios from "axios";
import {
    IUser,
    IUserCreate,
    IUserCreateWithOrganizations,
    IUserUpdate,
} from "./admin.types";
import { IFilter, Page } from "../api.types.ts";
import { buildFilterQuery } from "@utils";
const API = `${API_URL}/admin`;

export const getUsers = async (
    page: number = 1,
    size: number = 50,
    filter: IFilter
) => {
    const result = await axios.get<Page<IUser>>(
        `${API}/users?page=${page}&size=${size}&${buildFilterQuery(filter)}`
    );
    return result.data;
};

export const createUser = async (data: IUserCreate) => {
    const result: { data: IUser } = await axios.post(`${API}/users`, data);
    return result.data;
};

export const createUserWithOrganizations = async (
    data: IUserCreateWithOrganizations
) => {
    const user: { data: IUser } = await axios.post(`${API}/users`, {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: "Password123",
        is_admin: data.is_admin,
    });

    for (const organization of data.organizations) {
        await axios.post(
            `${API_URL}/organizations/${organization.id}/accounts`,
            {
                user_id: user.data.id!,
            }
        );
    }
};

export const getUserById = async (id: string | number) => {
    const result: { data: IUser } = await axios.get(`${API}/users/${id}`);
    return result.data;
};

export const updateUser = async (id: number, data: IUserUpdate) => {
    const result: { data: IUser } = await axios.put(`${API}/users/${id}`, data);
    return result.data;
};

export const deleteUser = async (id: number) => {
    const result: { data: IUser[] } = await axios.delete(`${API}/users/${id}`);
    return result.data;
};

export const searchUsers = async (search: string) => {
    const result = await axios.get<IUser[]>(
        `${API}/users/search?input=${search}`
    );

    return result.data;
};
