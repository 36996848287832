import { ILocation } from "@api";
import { theme } from "@config";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
    Box,
    Card,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { FormButton as Button } from "@rhf-kit/mui/dist/FormButton/FormButton";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import Map, { MapRef, Marker, ViewStateChangeEvent } from "react-map-gl";
import { GeolocateControl, NavigationControl } from "../location-map/controls";
import { LocationAutoComplete } from "./location-autocomplete";

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export interface IViewState {
    latitude: number;
    longitude: number;
    zoom: number;
}

interface IProps {
    /**
     * Handle closing the dialog
     */
    handleClose: () => void;
    /**
     * Current selected location
     */
    location?: ILocation;
    /**
     * Handle setting the form progress
     */
    handleFormProgress: (progress: string) => void;
}

/**
 * Location select component. Allows for selecting a location on a map
 */
export const LocationSelect = ({
    handleClose,
    location,
    handleFormProgress,
}: IProps) => {
    const { setValue } = useFormContext();
    const [mapLoaded, setMapLoaded] = useState(false);
    const [isMapTransitioning, setIsMapTransitioning] = useState(false);
    const [isMouseMoving, setIsMouseMoving] = useState(false);
    const mapRef = useRef<MapRef>(null);

    const [viewState, setViewState] = useState<IViewState>({
        latitude: location ? location.center_coordinates[0] : 44.967243,
        longitude: location ? location.center_coordinates[1] : -103.771556,
        zoom: location ? location.zoom : 15,
    });

    const handleMove = (event: ViewStateChangeEvent) => {
        if (
            event.originalEvent instanceof MouseEvent ||
            (typeof TouchEvent !== "undefined" &&
                event.originalEvent instanceof TouchEvent)
        ) {
            setIsMouseMoving(true);
        } else {
            setIsMapTransitioning(true);
        }

        setViewState(event.viewState);
    };

    const handleMoveEnd = () => {
        setIsMapTransitioning(false);
        setIsMouseMoving(false);
        setValue("coordinates", {
            latitude: viewState.latitude.toFixed(8),
            longitude: viewState.longitude.toFixed(8),
        });
        setValue("zoom", viewState.zoom.toFixed(0));
    };

    useEffect(() => {
        if (location && mapLoaded) {
            setViewState({
                latitude: location.center_coordinates[0],
                longitude: location.center_coordinates[1],
                zoom: location.zoom,
            });
        }
    }, [location, mapLoaded]);

    return (
        <>
            <DialogTitle
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
            >
                <Box display={"flex"} flexGrow={1}>
                    Choose a location
                </Box>
                <IconButton edge={"end"} size={"small"} onClick={handleClose}>
                    <CloseIcon fontSize={"small"} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Map
                    {...viewState}
                    ref={mapRef}
                    onLoad={() => setMapLoaded(true)}
                    onMove={handleMove}
                    onMoveEnd={handleMoveEnd}
                    mapboxAccessToken={MAPBOX_TOKEN}
                    style={{ width: "100%", height: "65vh" }}
                    mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
                    doubleClickZoom={false}
                    minZoom={1}
                    projection={{ name: "globe" }}
                >
                    <Marker
                        key={`${isMouseMoving || isMapTransitioning}`}
                        latitude={viewState.latitude}
                        longitude={viewState.longitude}
                        color={theme.palette.primary.main}
                        scale={1.5}
                        className={
                            isMouseMoving || isMapTransitioning
                                ? "mapboxgl-marker elevated"
                                : "mapboxgl-marker"
                        }
                    />

                    <Box
                        position={"relative"}
                        top={0}
                        left={0}
                        zIndex={10}
                        p={2}
                        width={"100%"}
                    >
                        <LocationAutoComplete
                            viewState={viewState}
                            mapRef={mapRef}
                            isMapTransitioning={isMapTransitioning}
                            isMouseMoving={isMouseMoving}
                        />
                    </Box>

                    <Card
                        sx={{
                            position: "relative",
                            top: 0,
                            left: 0,
                            maxWidth: 100,
                            mx: 2,
                            p: 2,
                        }}
                    >
                        <Typography variant={"body2"}>
                            Zoom: {viewState.zoom.toFixed(0)}
                        </Typography>
                    </Card>

                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        width={"100%"}
                        height={"100%"}
                    >
                        <Stack
                            justifyContent={"space-between"}
                            alignSelf={"flex-end"}
                            height={"100%"}
                            mb={4}
                        >
                            <Stack p={2} spacing={1}>
                                <GeolocateControl
                                    mapRef={mapRef}
                                    showLocation={!location}
                                    viewState={viewState}
                                />
                                <NavigationControl mapRef={mapRef} />
                            </Stack>
                        </Stack>
                    </Box>
                </Map>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Stack direction={"row"} spacing={1}>
                    <InfoIcon fontSize={"small"} color={"primary"} />
                    <Typography variant={"body2"}>
                        Drag map to move pin
                    </Typography>
                </Stack>

                <Button
                    type={"button"}
                    variant={"contained"}
                    disabled={isMapTransitioning}
                    onClick={() => handleFormProgress("details")}
                >
                    Continue
                </Button>
            </DialogActions>
        </>
    );
};
