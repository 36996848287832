import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@mui/material";
import {
    createOrganizationUser,
    deleteOrganizationAccount,
    IOrganization,
    IUser,
    updateUser,
} from "@api";
import { Person } from "@mui/icons-material";
import { FormContainer, FormButton as Button } from "@rhf-kit/mui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EditUserForm } from "./EditUserForm";
import { IUserUpdate } from "@api";
import { enqueueSnackbar } from "notistack";
import { CancelButton } from "@components";

interface IFormData {
    first_name: string;
    last_name: string;
    email: string;
    organizations: IOrganization[];
    is_admin: boolean;
}

interface IProps {
    user: IUser;
}

export const EditUserCard = ({ user }: IProps) => {
    const queryClient = useQueryClient();

    const { mutate, isPending } = useMutation({
        mutationFn: ({ id, data }: { id: number; data: IUserUpdate }) =>
            updateUser(id, data),
        onSuccess: async () => {
            queryClient.invalidateQueries({
                queryKey: ["userToEdit", String(user.id)],
            });

            enqueueSnackbar("User updated", {
                variant: "success",
            });
        },
        onError: () => {
            enqueueSnackbar("Failed to update user", {
                variant: "error",
            });
        },
    });

    const addUserToOrganizationMutation = useMutation({
        mutationFn: createOrganizationUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["userToEdit"] });
        },
    });

    const deleteUserFromOrganizationMutation = useMutation({
        mutationFn: deleteOrganizationAccount,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["userToEdit"] });
        },
    });

    const handleSubmit = (formData: IFormData) => {
        mutate({
            id: user.id,
            data: {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                is_admin: formData.is_admin,
                is_disabled: false,
            },
        });

        const selectedOrganizations = formData.organizations;

        const addedOrganizations = selectedOrganizations.filter(
            (org) =>
                !user.organization_accounts
                    .map((account) => account.organization)
                    .some((userOrg) => userOrg.id === org.id)
        );

        const removedOrganizations = user.organization_accounts
            .map((account) => account.organization)
            .filter(
                (userOrg) =>
                    !selectedOrganizations.some((org) => org.id === userOrg.id)
            );

        // Adding organizations
        for (const org of addedOrganizations) {
            addUserToOrganizationMutation.mutate({
                organizationId: org.id,
                userId: user.id.toString(),
            });
        }

        // Removing organizations
        for (const org of removedOrganizations) {
            const organizationAccount = user.organization_accounts.find(
                (account) => account.organization.id === org.id
            );

            if (!organizationAccount) return;

            deleteUserFromOrganizationMutation.mutate(organizationAccount.id);
        }
    };

    const defaultValues: IFormData = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        is_admin: user.is_admin,
        organizations: user.organization_accounts.map(
            (account) => account.organization
        ),
    };

    return (
        <Card>
            <FormContainer
                defaultValues={defaultValues}
                onSuccess={handleSubmit}
            >
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{
                                backgroundColor: "transparent",
                                boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.08);",
                            }}
                        >
                            <Person color="primary" />
                        </Avatar>
                    }
                    title={`Edit ${user.first_name} ${user.last_name}`}
                    titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                    <EditUserForm />
                </CardContent>
                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <CancelButton />
                    <Button
                        variant="contained"
                        loadingPosition="end"
                        loading={isPending}
                    >
                        Save Changes
                    </Button>
                </CardActions>
            </FormContainer>
        </Card>
    );
};
